<template>
  <div class="form-layout form-layout-2">
    <div class="report-summary-header mg-b-5 mg-t-0">
      <div>
        <h4 class="tx-inverse mg-b-3 d-block">Encerramento</h4>
        <p class="mg-b-0">
          <i class="icon ion-information-circled mg-r-3"></i>
          Informações de Encerramento do Processo de Importação.
        </p>
      </div>
      <div>
        <v-modal
          title="ENVIAR ENCERRAMENTO"
          :description="`Processo de Importação ${getTypeOfProcessReferenceByProcess(
            model
          )}`"
          :height="70"
          :width="800"
        >
          <template #activator="{ on }">
            <a href="#" @click.prevent="on" class="btn btn-info"
              ><i class="icon ion-plus tx-22"></i> ADICIONAR
            </a>
          </template>

          <template #content="{ opened }">
            <modal-closure
              v-if="opened"
              v-model="closureData"
              :dataModel="model"
            ></modal-closure>
          </template>

          <template #footer="{ closeModal }">
            <div class="d-flex align-items-center justify-content-end">
              <button
                class="btn btn-primary"
                @click.prevent="sendClosure(closeModal)"
              >
                ENVIAR ENCERRAMENTO
              </button>
            </div>
          </template>
        </v-modal>
      </div>
    </div>
    <div class="card processes-container">
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
          <li class="nav-item">
            <a
              class="nav-link active"
              @click="tab = 'tab-pi-encerramentos-dados'"
              href="#tab-pi-encerramentos-dados"
              data-toggle="tab"
            >
              Informações de Encerramento
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              @click="tab = 'tab-pi-encerramentos-enviados'"
              href="#tab-pi-encerramentos-enviados"
              data-toggle="tab"
            >
              Histórico de Encerramentos Enviados
            </a>
          </li>
        </ul>
      </div>

      <!-- card-header -->
      <div class="card-body">
        <div class="tab-content ht-100p">
          <div
            class="tab-pane"
            v-bind:class="{ active: tab === 'tab-pi-encerramentos-dados' }"
            id="tab-pi-encerramentos-dados"
          >
            <form
              id="formRevenues"
              class="form-layout form-layout-2 form-dates-sidebar"
            >
              <label class="section-title mg-t-0 mg-b-0--force"
                >Informações de Encerramento</label
              >

              <div class="row no-gutters">
                <div class="col-lg-2">
                  <div class="form-group bd-r-0-force mg-md-t--1">
                    <label class="form-control-label">Envio</label>
                    <div class="input-group-solved">
                      <div class="input-group-solved-before">
                        <i class="fa fa-calendar op-5"></i>
                      </div>
                      <div class="input-group-solved-center">
                        <date-picker
                          lang="pt-br"
                          v-model="formData.billingSendDate"
                          inputName="billingSendDate"
                          name="billingSendDate"
                          class="form-control"
                          format="DD/MM/YY"
                          v-validate="'required'"
                          placeholder="Selecione"
                        ></date-picker>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group mg-md-t--1 bd-r-0-force">
                    <label class="form-control-label">Enc Despachante</label>
                    <div class="input-group-solved">
                      <div class="input-group-solved-before">
                        <i class="fa fa-calendar op-5"></i>
                      </div>
                      <div class="input-group-solved-center">
                        <date-picker
                          lang="pt-br"
                          v-model="formData.billingCustomBrokerFinishDate"
                          inputName="billingCustomBrokerFinishDate"
                          name="billingCustomBrokerFinishDate"
                          class="form-control"
                          format="DD/MM/YY"
                          v-validate="'required'"
                          placeholder="Selecione"
                        ></date-picker>
                      </div>
                      <div class="input-group-solved-after">
                        <label class="ckbox wd-16 mg-b-0">
                          <input
                            v-model="
                              formData.billingCustomBrokerFinishDateSolved
                            "
                            name="billingCustomBrokerFinishDateSolved"
                            type="checkbox"
                          />
                          <span></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group bd-t-1-force bd-r-0-force mg-md-t--1">
                    <label class="form-control-label"
                      >Conferência de Dados</label
                    >
                    <div class="input-group-solved">
                      <div class="input-group-solved-before">
                        <i class="fa fa-calendar op-5"></i>
                      </div>
                      <div class="input-group-solved-center">
                        <date-picker
                          lang="pt-br"
                          v-model="formData.billingDataConferenceDate"
                          name="billingDataConferenceDate"
                          inputName="billingDataConferenceDate"
                          class="form-control"
                          format="DD/MM/YY"
                          v-validate="'required'"
                          placeholder="Selecione"
                        ></date-picker>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group bd-t-1-force bd-r-0-force mg-md-t--1">
                    <label class="form-control-label">Email</label>
                    <div class="input-group-solved">
                      <div class="input-group-solved-before">
                        <i class="fa fa-calendar op-5"></i>
                      </div>
                      <div class="input-group-solved-center">
                        <date-picker
                          lang="pt-br"
                          v-model="formData.billingEmailDate"
                          inputName="billingEmailDate"
                          name="billingEmailDate"
                          class="form-control"
                          format="DD/MM/YY"
                          v-validate="'required'"
                          placeholder="Selecione"
                        ></date-picker>
                      </div>
                      <div class="input-group-solved-after">
                        <label class="ckbox wd-16 mg-b-0">
                          <input
                            v-model="formData.billingEmailDateSolved"
                            type="checkbox"
                            name="billingEmailDateSolved"
                          />
                          <span></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group bd-t-1-force bd-r-0-force mg-md-t--1">
                    <label class="form-control-label">Empresa</label>
                    <div class="input-group-solved">
                      <div class="input-group-solved-before">
                        <i class="fa fa-info op-5"></i>
                      </div>
                      <div class="input-group-solved-center">
                        <input
                          class="form-control wd-300"
                          type="text"
                          v-model="formData.billingSendCompanyDescription"
                          name="billingSendCompanyDescription"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group bd-t-1-force mg-md-t--1">
                    <label class="form-control-label">Tracking</label>
                    <div class="input-group-solved">
                      <div class="input-group-solved-before">
                        <i class="fa fa-info op-5"></i>
                      </div>
                      <div class="input-group-solved-center">
                        <input
                          class="form-control wd-300"
                          type="text"
                          v-model="formData.billingSendTrackingDescription"
                          name="billingSendTrackingDescription"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row no-gutters mt-2">
                <div class="col-md-6">
                  <label class="section-title mg-t-10"
                    >Documentos Originais Arquivados
                    <small>(Prime)</small></label
                  >
                </div>
                <div class="col-md-6">
                  <label class="section-title mg-t-10"
                    >Documentos Originais Enviados
                    <small>(Cliente)</small></label
                  >
                </div>
              </div>

              <div class="row no-gutters">
                <div class="col-md-2">
                  <div class="form-group bd-r-0-force">
                    <label class="form-control-label">Invoice</label>
                    <div class="input-group-solved">
                      <div class="input-group-solved-before">
                        <i class="fa fa-info op-5"></i>
                      </div>
                      <div class="input-group-solved-center">
                        <input
                          class="form-control wd-100"
                          type="text"
                          v-model="formData.stayedInTheCompanyInvoiceQtd"
                          name="stayedInTheCompanyInvoiceQtd"
                        />
                      </div>
                      <div class="input-group-solved-after">
                        <label class="ckbox wd-16 mg-b-0 ckbox-danger">
                          <input
                            v-model="formData.stayedInTheCompanyInvoice"
                            name="stayedInTheCompanyInvoice"
                            type="checkbox"
                          />
                          <span></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group bd-r-0-force">
                    <label class="form-control-label">Packing List</label>
                    <div class="input-group-solved">
                      <div class="input-group-solved-before">
                        <i class="fa fa-info op-5"></i>
                      </div>
                      <div class="input-group-solved-center">
                        <input
                          class="form-control wd-100"
                          type="text"
                          v-model="formData.stayedInTheCompanyPackingListQtd"
                          name="stayedInTheCompanyPackingListQtd"
                        />
                      </div>
                      <div class="input-group-solved-after">
                        <label class="ckbox wd-16 mg-b-0 ckbox-danger">
                          <input
                            v-model="formData.stayedInTheCompanyPackingList"
                            name="stayedInTheCompanyPackingList"
                            type="checkbox"
                          />
                          <span></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group bd-r-0-force">
                    <label class="form-control-label">BL/AWB</label>
                    <div class="input-group-solved">
                      <div class="input-group-solved-before">
                        <i class="fa fa-info op-5"></i>
                      </div>
                      <div class="input-group-solved-center">
                        <input
                          class="form-control wd-100"
                          type="text"
                          v-model="formData.stayedInTheCompanyBLQtd"
                          name="stayedInTheCompanyBLQtd"
                        />
                      </div>
                      <div class="input-group-solved-after">
                        <label class="ckbox wd-16 mg-b-0 ckbox-danger">
                          <input
                            v-model="formData.stayedInTheCompanyBL"
                            name="stayedInTheCompanyBL"
                            type="checkbox"
                          />
                          <span></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group bd-r-0-force">
                    <label class="form-control-label">Invoice</label>
                    <div class="input-group-solved">
                      <div class="input-group-solved-before">
                        <i class="fa fa-info op-5"></i>
                      </div>
                      <div class="input-group-solved-center">
                        <input
                          class="form-control wd-100"
                          type="text"
                          v-model="formData.sentToCustomerInvoiceQtd"
                          name="sentToCustomerInvoiceQtd"
                        />
                      </div>
                      <div class="input-group-solved-after">
                        <label class="ckbox wd-16 mg-b-0 ckbox-info">
                          <input
                            v-model="formData.sentToCustomerInvoice"
                            name="sentToCustomerInvoice"
                            type="checkbox"
                          />
                          <span></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group bd-r-0-force">
                    <label class="form-control-label">Packing List</label>
                    <div class="input-group-solved">
                      <div class="input-group-solved-before">
                        <i class="fa fa-info op-5"></i>
                      </div>
                      <div class="input-group-solved-center">
                        <input
                          class="form-control wd-100"
                          type="text"
                          v-model="formData.sentToCustomerPackingListQtd"
                          name="sentToCustomerPackingListQtd"
                        />
                      </div>
                      <div class="input-group-solved-after">
                        <label class="ckbox wd-16 mg-b-0 ckbox-info">
                          <input
                            v-model="formData.sentToCustomerPackingList"
                            name="sentToCustomerPackingList"
                            type="checkbox"
                          />
                          <span></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group bd-r-1-force">
                    <label class="form-control-label">BL/AWB</label>
                    <div class="input-group-solved">
                      <div class="input-group-solved-before">
                        <i class="fa fa-info op-5"></i>
                      </div>
                      <div class="input-group-solved-center">
                        <input
                          class="form-control wd-100"
                          type="text"
                          v-model="formData.sentToCustomerBLQtd"
                          name="sentToCustomerBLQtd"
                        />
                      </div>
                      <div class="input-group-solved-after">
                        <label class="ckbox wd-16 mg-b-0 ckbox-info">
                          <input
                            v-model="formData.sentToCustomerBL"
                            name="sentToCustomerBL"
                            type="checkbox"
                          />
                          <span></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div
                    class="d-flex align-items-center justify-content-between mt-4"
                  >
                    <div>
                      <span
                        class="pd-10 text-white rounded-10 mr-2"
                        :class="
                          model.customer &&
                          model.customer.isMinimalComissionPercentual
                            ? 'bg-success'
                            : 'bg-secondary'
                        "
                      >
                        <strong
                          ><i class="fas fa-info-circle"></i> Comissão
                          mínima:</strong
                        >
                        {{
                          model.customer &&
                          model.customer.isMinimalComissionPercentual
                            ? "Sim"
                            : "Não"
                        }}
                      </span>
                      <span class="pd-10 bg-primary text-white rounded-10 mr-2">
                        <strong ><i class="fas fa-info-circle"></i> Comissão (%):</strong> {{ model.customer && model.customer.comissionPercentual ? model.customer.comissionPercentual : "" }}%
                      </span>
                      <template v-if="model.customer && model.customer.hasMultipleComissions">
                        <span class="pd-10 bg-danger tx-normal tx-white rounded-10"> Cliente tem mais do que uma comissão </span>
                      </template>
                    </div>
                    <button
                      @click.prevent="subitFormData"
                      class="btn btn-success"
                    >
                      SALVAR INFORMAÇÕES
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div
            class="tab-pane"
            v-bind:class="{ active: tab === 'tab-pi-encerramentos-enviados' }"
            id="tab-pi-encerramentos-enviados"
          >
            <label class="section-title mg-t-0 mg-b-0--force"
              >Histórico de Encerramentos Enviados</label
            >

            <div class="table-responsive">
              <table
                class="table display responsive nowrap dataTable no-footer dtr-inline tx-13 table-hover"
              >
                <thead class="thead-colored bg-teal">
                  <tr class="tx-10">
                    <th class="pd-y-5 tx-center"></th>
                    <th class="pd-y-5 tx-center">Tipo</th>
                    <th class="pd-y-5 tx-center">Complementar</th>
                    <th class="pd-y-5 tx-center">Encerramento</th>
                    <th class="pd-y-5 tx-center">Empresa</th>
                    <th class="pd-y-5 tx-center">Email Enviado</th>
                    <th class="pd-y-5 tx-center">Enviado Manualmente</th>
                    <th class="pd-y-5 tx-center">Contatos selecionados</th>
                    <!--                    <th class="pd-y-5 tx-center">Envio</th>-->
                    <!-- <th class="pd-y-5 tx-center">Email enviado</th> -->
                    <th class="pd-y-5 tx-center">Qtd Documentos</th>
                    <th class="pd-y-5 tx-center">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="closure in closures" :key="closure.id">
                    <td class="tx-left">
                      <a href="" class="tx-inverse tx-14 tx-medium d-block">{{
                        closure.subject
                      }}</a>
                      <span class="tx-11 d-block"
                        >ENVIANDO EM
                        {{ closure.sendDate | moment("DD/MM/YYYY") }}</span
                      >
                      <span
                        v-if="closure.errorDescription"
                        class="text-danger mt-3"
                      >
                        {{ closure.errorDescription }}
                      </span>
                    </td>
                    <td class="tx-left">{{ closure.type | closureType }}</td>
                    <td class="tx-center">
                      <i
                        class="fas fa-check text-success"
                        v-if="closure.additional"
                      ></i>
                      <i class="fas fa-times text-danger" v-else></i>
                    </td>
                    <td class="tx-center">
                      {{ closure.closingDate | moment("DD/MM/YY") }}
                    </td>
                    <td class="tx-left">{{ closure.company }}</td>
                    <td class="tx-center">
                      <span
                        v-if="closure.emailSended"
                        class="badge badge-success"
                        >Sim</span
                      >
                      <span v-else class="badge badge-danger">Não</span>
                    </td>
                    <td class="tx-center">
                      <span
                        v-if="closure.emailSentManually"
                        class="badge badge-success"
                        >Sim</span
                      >
                      <span v-else class="badge badge-danger">Não</span>
                    </td>
                    <td class="tx-left tx-11">
                      {{
                        closure.contacts
                          .map((contact) => contact.contact.name)
                          .join(", ")
                      }}
                    </td>
                    <td class="text-center">{{ closure.documents.length }}</td>
                    <td>
                      <div class="dropdown">
                        <button
                          class="btn dropdown-toggle rounded"
                          type="button"
                          data-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i class="fa fa-ellipsis-h mr-1"></i>
                        </button>
                        <div class="dropdown-menu">
                          <a
                            class="dropdown-item"
                            @click.prevent="setEmailSentManually(closure.id)"
                            >Enviado Manualmente</a
                          >
                        </div>
                      </div>
                    </td>
                    <!--                    <td class="tx-center">-->
                    <!--                      <a href="" class="tx-inverse tx-14 tx-medium d-block">{{-->
                    <!--                        closure.subject-->
                    <!--                      }}</a>-->
                    <!--                      <span class="tx-11 d-block"-->
                    <!--                        >ENVIANDO EM-->
                    <!--                        {{ closure.sendDate | moment("DD/MM/YYYY") }}</span-->
                    <!--                      >-->
                    <!--                    </td>-->
                    <!--                    <td class="tx-left">{{ closure.type | closureType }}</td>-->
                    <!--                    <td class="tx-center">-->
                    <!--                      <i-->
                    <!--                        class="fas fa-check text-success"-->
                    <!--                        v-if="closure.additional"-->
                    <!--                      ></i>-->
                    <!--                      <i class="fas fa-times text-danger" v-else></i>-->
                    <!--                    </td>-->
                    <!--                    <td class="tx-center">-->
                    <!--                      {{ closure.closingDate | moment("DD/MM/YY") }}-->
                    <!--                    </td>-->
                    <!--                    <td class="tx-left">{{ closure.company }}</td>-->
                    <!--                    <td class="tx-center">-->
                    <!--                      <i-->
                    <!--                        class="fa fa-check text-success"-->
                    <!--                        v-if="closure.emailSended"-->
                    <!--                      ></i>-->
                    <!--                      <i class="fa fa-times text-danger" v-else></i>-->
                    <!--                    </td>-->
                    <!--                    <td class="tx-left tx-11">-->
                    <!--                      {{-->
                    <!--                        closure.contacts-->
                    <!--                          .map((contact) => contact.contact.name)-->
                    <!--                          .join(", ")-->
                    <!--                      }}-->
                    <!--                    </td>-->
                    <!--                    <td class="tx-center">-->
                    <!--                      {{ closure.sendDate | moment("DD/MM/YY") }}-->
                    <!--                    </td>-->
                    <!-- <td style="text-align: center;" v-if="closure.emailSended !== null">{{ closure.emailSended ? 'Sim' : 'Não' }}</td>
                            <td style="text-align: center;" v-else>-</td> -->
                    <!--                    <td class="tx-center">{{ closure.documents.length }}</td>-->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import config from "@/config.js";
import { mapState, mapActions } from "vuex";
import DatePicker from "vue2-datepicker";
import VModal from "@/components/VModal.vue";
import Swal from "sweetalert2";
import ProcessService from "@/services/ProcessService.js";
import ModalClosure from "./modalClosure.vue";

export default {
  name: "OpClosure",
  props: [],
  components: {
    DatePicker,
    VModal,
    ModalClosure,
  },
  data() {
    return {
      tab: "tab-pi-encerramentos-dados",
      closureData: {},
      money: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
      dolar: {
        decimal: ",",
        thousands: ".",
        precision: 4,
        masked: false,
      },
      model: {},
      closures: [],
      formData: {
        billingSendDate: null,
        billingCustomBrokerFinishDate: null,
        billingCustomBrokerFinishDateSolved: null,
        billingDataConferenceDate: null,
        billingEmailDate: null,
        billingEmailDateSolved: null,
        billingSendCompanyDescription: null,
        billingSendTrackingDescription: null,
        stayedInTheCompanyInvoiceQtd: null,
        stayedInTheCompanyInvoice: null,
        stayedInTheCompanyPackingListQtd: null,
        stayedInTheCompanyPackingList: null,
        stayedInTheCompanyBLQtd: null,
        stayedInTheCompanyBL: null,
        sentToCustomerInvoiceQtd: null,
        sentToCustomerInvoice: null,
        sentToCustomerPackingListQtd: null,
        sentToCustomerPackingList: null,
        sentToCustomerBLQtd: null,
        sentToCustomerBL: null,
      },
      storagePeriodsOptions: [
        {
          value: "PRIMEIRO_PERIODO",
          description: "Primeiro período",
        },
        {
          value: "SEGUNDO_PERIODO",
          description: "Segundo período",
        },
        {
          value: "TERCEIRO_PERIODO",
          description: "Terceiro período",
        },
        {
          value: "QUARTO_PERIODO",
          description: "Quarto período",
        },
        {
          value: "OUTROS",
          description: "Outros",
        },
      ],
    };
  },

  computed: {
    ...mapState({
      process: (state) => state.ProcessStore.activeProcess,
    }),
    options() {
      return [
        "blur",
        (e) => {
          setTimeout(() => {
            this.diffObjects();
          }, 200);
        },
        true,
      ];
    },
  },
  methods: {
    ...mapActions("ProcessStore", ["setCanConclude"]),
    getTypeOfProcessReferenceByProcess(process) {
      return ProcessService.getTypeOfProcessReference(process);
    },
    async setEmailSentManually(id) {
      try {
        const result = await Swal.fire({
          title: "Marcar como enviado manualmente?",
          text: "Você tem certeza que deseja fazer marcar este encerramento como enviado manualmente?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "##23BF08",
          cancelButtonColor: "#dc3545",
          confirmButtonText: "Sim, continuar!",
          cancelButtonText: "Cancelar",
        });

        if (result.value) {
          this.$store.commit("setLoading", {
            show: true,
            label: "Salvando Informações",
            description: "Carregando...",
          });

          const response = Axios.put(
            `${config.env.API_DEFAULT.host}/process-financial-closing/${id}/mark-email-sent-manually`
          );

          this.$store.commit("setLoading", {
            show: false,
          });

          Swal.fire(
            "Sucesso!",
            "Encerramento marcado como enviado manualmente!",
            "success"
          );

          this.getClosures();
          await this.checkIfCanConcludeProcess();
        }
      } catch (e) {
        console.error(e);

        this.$store.commit("setLoading", {
          show: false,
        });

        Swal.fire("Ops!", "Erro inesperado ao salvar os dados!", "error");
      }
    },
    async subitFormData() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Salvando Informações",
        description: "Carregando...",
      });

      try {
        const response = await Axios.put(
          `${config.env.API_DEFAULT.host}/process/import/save-clousing-tab-data/${this.model.id}`,
          this.formData
        );

        this.setModelData();
        Swal.fire("Sucesso!", "Dados salvos com sucesso!", "success");
      } catch (e) {
        Swal.fire("Ops!", "Erro inesperado ao salvar os dados!", "error");
        console.error(e);
      }

      this.$store.commit("setLoading", {
        show: false,
      });
    },
    setModelData() {
      this.model.billingSendDate = this.formData.billingSendDate;
      this.model.billingCustomBrokerFinishDate =
        this.formData.billingCustomBrokerFinishDate;
      this.model.billingCustomBrokerFinishDateSolved =
        this.formData.billingCustomBrokerFinishDateSolved;
      this.model.billingDataConferenceDate =
        this.formData.billingDataConferenceDate;
      this.model.billingEmailDate = this.formData.billingEmailDate;
      this.model.billingEmailDateSolved = this.formData.billingEmailDateSolved;
      this.model.billingSendCompanyDescription =
        this.formData.billingSendCompanyDescription;
      this.model.billingSendTrackingDescription =
        this.formData.billingSendTrackingDescription;
      this.model.stayedInTheCompanyInvoiceQtd =
        this.formData.stayedInTheCompanyInvoiceQtd;
      this.model.stayedInTheCompanyInvoice =
        this.formData.stayedInTheCompanyInvoice;
      this.model.stayedInTheCompanyPackingListQtd =
        this.formData.stayedInTheCompanyPackingListQtd;
      this.model.stayedInTheCompanyPackingList =
        this.formData.stayedInTheCompanyPackingList;
      this.model.stayedInTheCompanyBLQtd =
        this.formData.stayedInTheCompanyBLQtd;
      this.model.stayedInTheCompanyBL = this.formData.stayedInTheCompanyBL;
      this.model.sentToCustomerInvoiceQtd =
        this.formData.sentToCustomerInvoiceQtd;
      this.model.sentToCustomerInvoice = this.formData.sentToCustomerInvoice;
      this.model.sentToCustomerPackingListQtd =
        this.formData.sentToCustomerPackingListQtd;
      this.model.sentToCustomerPackingList =
        this.formData.sentToCustomerPackingList;
      this.model.sentToCustomerBLQtd = this.formData.sentToCustomerBLQtd;
      this.model.sentToCustomerBL = this.formData.sentToCustomerBL;
    },
    async diffObjects() {
      const response = await this.$store.dispatch("ProcessStore/diffObjects", {
        initialModel: this.initialModel,
        model: this.model,
      });

      if (response) this.initialModel = { ...this.model };
    },

    changeRevenuesRelease() {},
    changeRevenuesRoad() {},
    async checkIfCanConcludeProcess() {
      if(this.process.id !== null) {
        try {
          const response = await Axios.get(
            config.env.API_DEFAULT.host +
              `/newImportProcess/${this.model.id}/check-if-can-conclude`
          );

          this.setCanConclude(response.data.data);
        } catch (e) {
          console.log(e);

          Swal.fire(
            "Verificação de Conclusão",
            "Erro ao tentar verificar se é possível concluir o processo",
            "error"
          );
        }
      }
    },

    sendClosure(close) {
      this.$store.commit("setLoading", {
        show: true,
        label: "Enviando Encerramento",
        description: "Carregando...",
      });

      if (!this.closureData.contactsCC) {
        this.closureData.contactsCC = [];
      }

      Axios.post(
        `${config.env.API_DEFAULT.host}/process-financial-closing/${this.model.id}`,
        {
          ...this.closureData,
          additional: false,
        }
      )
        .then(async (response) => {
          close();
          this.getClosures();
          this.$store.commit("setLoading", {
            show: false,
          });
          this.closureData = {};

          this.closureData.closingDate = new Date();
          this.closureData.sendDate = this.process.billingSendDate;
          this.closureData.company = this.process.billingSendCompanyDescription;

          if (response.data.data.emailSended) {
            Swal.fire(
              "Sucesso!",
              "Encerramento cadastrado com sucesso!",
              "success"
            );
          } else {
            Swal.fire(
              "Email não enviado!",
              "O Encerramento foi cadastrado com sucesso, porém o email pode não foi enviado devido à alguma instabilidade, verifique sua caixa de entrada e tente novamente!",
              "warning"
            );
          }

          await this.checkIfCanConcludeProcess();
        })
        .catch((error) => {
          if(error?.response?.data?.data){
            Swal.fire("Enviar Encerramento", error.response.data.data, "error");
          } else{
            Swal.fire("Enviar Encerramento", error, "error");
          }

          this.$store.commit("setLoading", {
            show: false,
          });
        });
    },
    getClosures() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Buscando Encerramentos",
        description: "Carregando...",
      });

      Axios.get(
        `${config.env.API_DEFAULT.host}/process-financial-closing/${this.model.id}`
      )
        .then((response) => {
          this.closures = response.data.data;

          this.$store.commit("setLoading", {
            show: false,
          });
        })
        .catch((error) => {
          this.$store.commit("setLoading", {
            show: false,
          });
        });
    },
  },
  created() {
    this.model = { ...this.process };
    this.initialModel = { ...this.process };
    this.getClosures();
  },
  mounted() {
    this.closureData.closingDate = new Date();
    this.closureData.sendDate = this.process.billingSendDate;
    this.closureData.company = this.process.billingSendCompanyDescription;

    if (window.addEventListener) {
      setTimeout(() => {
        const form = document.getElementById("formRevenues");
        form.addEventListener(...this.options);
      }, 200);
    }

    // Set Form Data
    this.formData.billingSendDate = this.model.billingSendDate;
    this.formData.billingCustomBrokerFinishDate =
      this.model.billingCustomBrokerFinishDate;
    this.formData.billingCustomBrokerFinishDateSolved =
      this.model.billingCustomBrokerFinishDateSolved;
    this.formData.billingDataConferenceDate =
      this.model.billingDataConferenceDate;
    this.formData.billingEmailDate = this.model.billingEmailDate;
    this.formData.billingEmailDateSolved = this.model.billingEmailDateSolved;
    this.formData.billingSendCompanyDescription =
      this.model.billingSendCompanyDescription;
    this.formData.billingSendTrackingDescription =
      this.model.billingSendTrackingDescription;
    this.formData.stayedInTheCompanyInvoiceQtd =
      this.model.stayedInTheCompanyInvoiceQtd;
    this.formData.stayedInTheCompanyInvoice =
      this.model.stayedInTheCompanyInvoice;
    this.formData.stayedInTheCompanyPackingListQtd =
      this.model.stayedInTheCompanyPackingListQtd;
    this.formData.stayedInTheCompanyPackingList =
      this.model.stayedInTheCompanyPackingList;
    this.formData.stayedInTheCompanyBLQtd = this.model.stayedInTheCompanyBLQtd;
    this.formData.stayedInTheCompanyBL = this.model.stayedInTheCompanyBL;
    this.formData.sentToCustomerInvoiceQtd =
      this.model.sentToCustomerInvoiceQtd;
    this.formData.sentToCustomerInvoice = this.model.sentToCustomerInvoice;
    this.formData.sentToCustomerPackingListQtd =
      this.model.sentToCustomerPackingListQtd;
    this.formData.sentToCustomerPackingList =
      this.model.sentToCustomerPackingList;
    this.formData.sentToCustomerBLQtd = this.model.sentToCustomerBLQtd;
    this.formData.sentToCustomerBL = this.model.sentToCustomerBL;
  },
  filters: {
    closureType(value) {
      if (value === "CLOSING_WITH_DEBIT") {
        return "Encerramento com débito";
      }
      if (value === "CLOSING_WITH_CREDIT") {
        return "Encerramento com crédito";
      }
      if (value === "NEW_CUSTOMERS_WITHOUT_BANK_DATA_AND_NATHOR") {
        return "Clientes novos sem dados bancários e nathor";
      }
      if (value === "CUSTOMERS_WHO_PAY_BY_DEPOSIT") {
        return "Clientes que pagam através de depósito";
      }
    },
  },
  beforeDestroy() {},
  watch: {
    process() {
      this.model = { ...this.process };
    },
  },
};
</script>

<style scoped></style>
