<template>
  <multiselect
    :value="value"
    @input="changeItem"
    selectLabel=""
    selectGroupLabel="Selecionar Grupo"
    selectedLabel=""
    deselectLabel="X"
    deselectGroupLabel="Retirar Seleção do Grupo"
    allowEmpty
    searchable
    v-bind="config"
    :class="{
      telao: telao
    }"
  >
    <template #option v-if="$scopedSlots.option"></template>
    <template #noOptions>
      <slot name="noOptions">
        Nada por aqui
      </slot>
    </template>
    <template #noResult>
      <slot name="noResult">
        Nenhum resultado encontrado
      </slot>
    </template>

    <template #caret>
      <div class="multiselect__select">
        <i role="presentation" class="open-indicator"></i>
      </div>
    </template>
  </multiselect>
</template>

<script>
// Documentação do componente
// https://vue-multiselect.js.org/#sub-getting-started

import Multiselect from 'vue-multiselect'

export default {
  name: 'MultiSelect',
  components: {
    Multiselect,
  },
  props: {
    value: {},
    config: {
      type: Object,
      default: () => {}
    },
    telao: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    changeItem(value) {
      // console.log(value)
      this.$emit('input', value);
      this.$emit('change', value);
      this.$emit('blur', value);
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.multiselect__content-wrapper {
  overflow-x: hidden;
  border: 1px solid rgba(0, 0, 0, .26) !important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .15) !important;
}

.multiselect,
.multiselect__input,
.multiselect__single {
  font-size: inherit !important;
}

.multiselect__select {
  z-index: 2;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 30px !important;
  height: 30px !important;
}

.multiselect__select .open-indicator {
  border-color: rgba(60, 60, 60, .5);
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: inline-block;
  height: 10px;
  width: 10px;
  vertical-align: text-top;
  transform: rotate(133deg);
  box-sizing: inherit;
}

.multiselect__select::before {
  display: none !important;
}

.multiselect__option {
  width: 100%;
  overflow: hidden;
  white-space: break-spaces;
}

.telao .multiselect__tags {
  border-radius: 0 !important;
  border: 0 !important;
}

.telao .multiselect__tags,
.telao .multiselect__single {
  padding-left: 0;
  padding-right: 0;
}

.telao .multiselect__single {
  margin-bottom: 0;
}

.telao.multiselect,
.telao .multiselect__tags {
  min-height: unset !important;
}

body .multiselect__input {
  background-color: transparent !important;
  padding-left: 0;
}

.multiselect__option--highlight,
.multiselect__option--highlight::after {
  background: #5897fb !important;
}

.multiselect__option {
  min-height: unset;
  line-height: 1.5;
  padding: 3px 20px;
}

</style>