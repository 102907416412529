<template>
  <div class="slim-mainpanel">
    <div class="container">
      <div class="slim-pageheader">
        <ol class="breadcrumb slim-breadcrumb">
          <li class="breadcrumb-item"><a href="#">Operacional</a></li>
          <li class="breadcrumb-item"><a href="#">Relatórios</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            Relatório de Inconsistências
          </li>
        </ol>
        <h6 class="slim-pagetitle">Relatório de Inconsistências</h6>
      </div>

      <div class="bd bd-1 bd-b-0">
        <div
          class="bd-t bd-5 bd-gray-800 pd-x-10 bg-white d-flex justify-content-between align-items-center pd-b-5 pd-t-5"
        >
          <ul class="nav gap1">
            <li class="mg-t-5">
              <div class="dropdown dropdown-demo">
                <a href="#" class="dd-link" data-toggle="dropdown">
                  <div
                    v-if="filter.date.start !== '' && filter.date.end !== ''"
                  >
                    <span class="mg-l-0 pd-l-0">
                      <i class="fa fa-calendar"></i> Período de Inconsistência:
                      <span
                        class="pd-x-5 badge mg-b-0 mg-r-2-force tx-thin-force"
                      >
                        {{ filter.date.start | moment("DD/MM/YYYY") }} até
                        {{ filter.date.end | moment("DD/MM/YYYY") }}
                      </span>
                      <i class="fa fa-angle-down mg-l-5"></i>
                    </span>
                  </div>

                  <div v-else>
                    <span> <i class="fa fa-calendar mg-r-5"></i> Período</span>
                    <i class="fa fa-angle-down mg-l-5"></i>
                  </div>
                </a>
                <div class="dropdown-menu pd-15 wd-400" @click.stop="keepOpen">
                  <h6 class="tx-gray-800 tx-uppercase tx-bold mg-b-0 mg-t-10">
                    Data do Período
                  </h6>
                  <p class="tx-gray-600 mg-b-10">
                    Selecione uma data inicial e final
                  </p>

                  <div class="form-layout form-layout-2 modify">
                    <div class="row no-gutters">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="form-control-label">
                            Data Inicial <span class="tx-danger">*</span></label
                          >
                          <date-picker lang="pt-br"
                                       v-model="filter.date.start"
                                       name="toPickedDate"
                                       format="DD/MM/YYYY"
                                       placeholder="Data Inicial"
                          ></date-picker>
                        </div>
                      </div>
                      <div class="col-md-6 mg-t--1 mg-md-t-0">
                        <div class="form-group mg-md-l--1">
                          <label class="form-control-label">
                            Data Final <span class="tx-danger">*</span></label
                          >
                          <date-picker lang="pt-br"
                                       v-model="filter.date.end"
                                       name="toPickedDate"
                                       format="DD/MM/YYYY"
                                       placeholder="Data Final"
                          ></date-picker>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="mg-t-5">
              <div class="dropdown dropdown-demo" data-pesistent>
                <a href="#" class="dd-link tx-light" data-toggle="dropdown">
                  <div v-if="filter.customers.length > 0">
                    <span class="mg-l-0 pd-l-0">
                      <i class="fa fa-building tx-danger mg-r-5"></i>Clientes:
                      <span
                        class="pd-x-5 badge badge-danger mg-b-0 mg-r-2-force tx-thin-force"
                        v-for="c in filter.customers"
                        :key="c.id"
                      >
                        {{ c.name }}</span
                      >
                      <i class="fa fa-angle-down mg-l-5"></i>
                    </span>
                  </div>

                  <div v-else>
                    <span
                    ><i class="fa fa-building tx-medium mg-r-5"></i> Clientes
                    </span>
                    <i class="fa fa-angle-down mg-l-5"></i>
                  </div>
                </a>
                <div class="dropdown-menu pd-15 wd-500" @click.stop="keepOpen">
                  <h6 class="tx-gray-800 tx-uppercase tx-bold mg-b-0 mg-t-10">
                    Clientes
                  </h6>
                  <p class="tx-gray-600 mg-b-10">
                    Selecione um ou mais clientes ({{
                      this.listOfCustomers.length
                    }}
                    encontrados)
                  </p>

                  <div class="form-layout form-layout-2">
                    <div class="row no-gutters">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="form-control-label"> Clientes </label>
                          <v-select
                            v-model="filter.customers"
                            label="name"
                            :options="optionsCustomers"
                            name="customers"
                            multiple
                            :on-search="getCustomers"
                          >
                            <template slot="option" slot-scope="option">
                              {{ option.name }}
                            </template>
                          </v-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="mg-t-5">
              <div class="dropdown dropdown-demo" data-pesistent>
                <a href="#" class="dd-link tx-light" data-toggle="dropdown">
                  <div v-if="filter.teams.length > 0">
                    <span class="mg-l-0 pd-l-0">
                      <i class="fa fa-users tx-danger mg-r-5"></i>Times Operacionais:
                      <span
                        class="pd-x-5 badge badge-danger mg-b-0 mg-r-2-force tx-thin-force"
                        v-for="c in filter.teams"
                        :key="c.id"
                      >
                        {{ c.name }}</span
                      >
                      <i class="fa fa-angle-down mg-l-5"></i>
                    </span>
                  </div>

                  <div v-else>
                    <span
                    ><i class="fa fa-users tx-medium mg-r-5"></i> Times Operacionais
                    </span>
                    <i class="fa fa-angle-down mg-l-5"></i>
                  </div>
                </a>
                <div class="dropdown-menu pd-15 wd-500" @click.stop="keepOpen">
                  <h6 class="tx-gray-800 tx-uppercase tx-bold mg-b-0 mg-t-10">
                    Times Operacionais:
                  </h6>
                  <p class="tx-gray-600 mg-b-10">
                    Selecione um ou mais times ({{
                      this.listOfTeams.length
                    }}
                    encontrados)
                  </p>

                  <div class="form-layout form-layout-2">
                    <div class="row no-gutters">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="form-control-label"> Times Operacionais </label>
                          <v-select
                            v-model="filter.teams"
                            label="name"
                            :options="optionsTeams"
                            name="customers"
                            multiple
                            :on-search="getTeams"
                          >
                            <template slot="option" slot-scope="option">
                              {{ option.name }}
                            </template>
                          </v-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="mg-t-5">
              <div class="dropdown dropdown-demo" data-pesistent>
                <a href="#" class="dd-link tx-light" data-toggle="dropdown">
                  <div v-if="filter.companies.length > 0">
                    <span class="mg-l-0 pd-l-0">
                      <i class="fa fa-building tx-teal mg-r-5"></i>Fornecedor:
                      <span
                        class="pd-x-5 badge badge-teal mg-b-0 mg-r-2-force tx-thin-force"
                        v-for="c in filter.companies"
                        :key="c.id"
                      >
                        {{ c.name }}</span
                      >
                      <i class="fa fa-angle-down mg-l-5"></i>
                    </span>
                  </div>

                  <div v-else>
                    <span
                    ><i class="fa fa-building tx-medium mg-r-5"></i>
                      Fornecedor
                    </span>
                    <i class="fa fa-angle-down mg-l-5"></i>
                  </div>
                </a>
                <div class="dropdown-menu pd-15 wd-500" @click.stop="keepOpen">
                  <h6 class="tx-gray-800 tx-uppercase tx-bold mg-b-0 mg-t-10">
                    Fornecedor
                  </h6>
                  <p class="tx-gray-600 mg-b-10">
                    Selecione um ou mais fornecedor ({{
                      this.listOfCompanies.length
                    }}
                    encontrados)
                  </p>

                  <div class="form-layout form-layout-2">
                    <div class="row no-gutters">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="form-control-label"> Fornecedor </label>
                          <v-select
                            v-model="filter.companies"
                            label="name"
                            :options="optionsCompanies"
                            name="companies"
                            multiple
                            :on-search="getCompanies"
                          >
                            <template slot="option" slot-scope="option">
                              {{ option.name }}
                            </template>
                          </v-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="mg-t-5">
              <div class="dropdown dropdown-demo" data-pesistent>
                <a href="#" class="dd-link tx-light" data-toggle="dropdown">
                  <div v-if="filter.contacts.length > 0">
                    <span class="mg-l-0 pd-l-0">
                      <i class="fa fa-building tx-primary mg-r-5"></i>Contatos:
                      <span
                        class="pd-x-5 badge badge-primary mg-b-0 mg-r-2-force tx-thin-force"
                        v-for="c in filter.contacts"
                        :key="c.id"
                      >
                        {{ c.name }}</span
                      >
                      <i class="fa fa-angle-down mg-l-5"></i>
                    </span>
                  </div>

                  <div v-else>
                    <span
                    ><i class="fa fa-users tx-medium mg-r-5"></i> Contatos
                    </span>
                    <i class="fa fa-angle-down mg-l-5"></i>
                  </div>
                </a>
                <div class="dropdown-menu pd-15 wd-500" @click.stop="keepOpen">
                  <h6 class="tx-gray-800 tx-uppercase tx-bold mg-b-0 mg-t-10">
                    Contatos
                  </h6>
                  <p class="tx-gray-600 mg-b-10">
                    Selecione um ou mais contato ({{
                      this.listOfContacts.length
                    }}
                    encontrados)
                  </p>

                  <div class="form-layout form-layout-2">
                    <div class="row no-gutters">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="form-control-label"> Contatos </label>
                          <v-select
                            v-model="filter.contacts"
                            label="name"
                            :on-search="getUsers"
                            :options="optionsUsers"
                            name="contacts"
                            multiple
                          >
                            <template slot="option" slot-scope="option">
                              {{ option.name }}
                            </template>
                          </v-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="mg-t-5">
              <div class="dropdown dropdown-demo">
                <a href="#" class="dd-link" data-toggle="dropdown">
                  <div v-if="filter.steps.length > 0">
                    <span class="mg-l-0 pd-l-0">
                      <i class="fa fa-flag tx-info mg-r-5"></i>Etapas:
                      <span
                        class="pd-x-5 badge mg-b-0 mg-r-2-force tx-thin-force"
                        :style="
                          'background: ' +
                          c.color +
                          '; color: ' +
                          c.textColor +
                          ' !important;'
                        "
                        v-for="c in filter.steps"
                        :key="c.id"
                      >
                        {{ c.description }}
                      </span>
                      <i class="fa fa-angle-down mg-l-5"></i>
                    </span>
                  </div>

                  <div v-else>
                    <span
                    ><i class="fa fa-flag tx-medium mg-r-5"></i> Etapas
                    </span>
                    <i class="fa fa-angle-down mg-l-5"></i>
                  </div>
                </a>
                <div class="dropdown-menu pd-15 wd-300" @click.stop="keepOpen">
                  <h6 class="tx-gray-800 tx-uppercase tx-bold mg-b-0 mg-t-10">
                    Etapas
                  </h6>
                  <p class="tx-gray-600 mg-b-10">Selecione um ou mais etapas</p>
                  <div class="form-layout form-layout-2">
                    <div class="row no-gutters">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="form-control-label"> Etapas </label>
                          <v-select
                            v-model="filter.steps"
                            label="name"
                            :options="steps"
                            name="steps"
                            multiple
                          >
                            <template slot="option" slot-scope="option">
                              {{ option.description }}
                            </template>
                          </v-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <ul class="nav nav-white-800 flex-column flex-md-row tx-white tx-15">
            <li class="mg-l-10">
              <div class="btn-group" role="group" aria-label="Ações">
                <button
                  type="button"
                  class="btn btn-primary pd-x-20"
                  @click.prevent="generate('HTML')"
                >
                  <i class="fa fa-file-code-o mg-r-5"></i> GERAR
                </button>
              </div>
            </li>
          </ul>
        </div>

        <div class="section-wrapper mg-t-0 pd-0 bd-1 bd-l-0 bd-r-0 bd-b-1">
          <div class="pd-10 mg-10">
            <label class="section-title mg-t-10 tx-16">
              Resultado da Consulta
            </label>
            <p class="mg-b-0">
              Foram encontrados {{ listOfResults.length }} Inconsistências de
              acordo com o filtro acima.
            </p>
            <hr/>
            <div class="table-responsive">
              <table class="table table-striped mg-b-0 tx-gray-800 tx-12">
                <thead>
                <tr>
                  <th class="tx-center">Ref</th>
                  <th class="tx-center">Ref do Cliente</th>
                  <th class="tx-center">Data</th>
                  <th class="tx-center">Origem</th>
                  <th class="tx-center">Time Operacional</th>
                  <th class="tx-center">Descrição</th>
                  <th class="tx-center">Tipo</th>
                  <th class="tx-center">Valor R$</th>
                  <th class="tx-center">Peso</th>
                  <th class="tx-center">Etapa do Requisito</th>
                </tr>
                </thead>
                <tbody v-if="this.listOfResults != null && this.listOfResults.length > 0">
                <tr v-for="result in listOfResults" :key="result.id">
                  <td class="tx-center tx-uppercase tx-bold">
                    <a href="#"  @click.prevent=" findProcessByIdentificationSearch( result.importProcess.identification)">
                      {{ ProcessService.getTypeOfProcessReference(result.importProcess) }}
                    </a>
                  </td>
                  <td class="tx-center">
                    {{ result.importProcess.customerRef }}
                  </td>

                  <td class="tx-center">
                    {{ result.date | moment("DD/MM/YYYY") }}
                  </td>

                  <td class="valign-middle tx-center tx-uppercase">
                      <span
                        class="tx-danger tx-bold tx-lato"
                        v-if="result.source === 'INTERNA'"
                      >
                        Interna
                      </span>
                    <span
                      class="tx-warning tx-bold tx-lato"
                      v-else-if="result.source === 'FORNECEDOR'"
                    >
                        Fornecedor
                      </span>
                    <span
                      class="tx-primary tx-bold tx-lato"
                      v-else-if="result.source === 'CLIENTE'"
                    >
                        Cliente
                      </span>
                    <br/>
                    <small
                      v-if="
                          result.employee !== undefined &&
                          result.employee !== null &&
                          result.source === 'INTERNA'
                        "
                    >
                      {{ result.employee.name }}</small
                    >
                    <small
                      v-else-if="
                          result.company !== undefined &&
                          result.company !== null &&
                          result.source === 'FORNECEDOR'
                        "
                    >
                      {{ result.company.name }}</small
                    >
                    <small
                      v-else-if="
                          result.company !== undefined &&
                          result.company !== null &&
                          result.source === 'CLIENTE'
                        "
                    >
                      {{ result.company.name }}</small
                    >
                  </td>
                  <td>
                    <span v-if="result.importProcess.typeOfProcess === 'ASSESSORIA'" class="text-sm">
                      {{ result.importProcess.customer.teamAdvisory.name }}
                    </span>
                    <span v-if="result.importProcess.typeOfProcess === 'ENCOMENDA'" class="text-sm">
                      {{ result.importProcess.customer.teamOrder.name }}
                    </span>
                    <span v-if="result.importProcess.typeOfProcess === 'CONTA_E_ORDEM'" class="text-sm">
                      {{ result.importProcess.customer.team.name }}
                    </span>
                  </td>
                  <td class="valign-middle tx-center">
                    {{ result.description }}
                  </td>

                  <td class="valign-middle tx-center tx-bold">
                      <span v-if="result.type != null">
                        {{ result.type.description }}
                      </span>
                  </td>

                  <td class="valign-middle tx-center tx-right">
                    {{ result.value | numeralFormat("0,0.00") }}
                  </td>

                  <td class="valign-middle tx-center tx-center">
                    {{ result.weight }}
                  </td>

                  <td class="tx-center tx-uppercase tx-bold">
                      <span
                        v-if="
                          result.step !== null && result.step.color !== null
                        "
                        :style="'color: ' + result.step.color"
                      >
                        {{ result.step.description }}
                      </span>
                    <span v-else>
                        {{ result.step.description }}
                      </span>
                  </td>
                </tr>
                </tbody>

                <tbody v-else>
                <tr>
                  <td
                    class="valign-middle tx-center tx-center tx-uppercase tx-bold pd-5"
                    colspan="10"
                  >
                    Nenhuma inconsistência foi encontrada!
                  </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                  <td class="valign-middle tx-center tx-right" colspan="7">
                    Valor Total
                  </td>
                  <td class="valign-middle tx-center tx-right">
                    {{
                      totalValuesOfInconsistencies | numeralFormat("0,0.00")
                    }}
                  </td>
                  <td
                    class="valign-middle tx-center tx-right"
                    colspan="2"
                  ></td>
                </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import Swal from "sweetalert2";
import DatePicker from "vue2-datepicker";

import config from "@/config.js";
import * as labelsProcesses from "@/labels/labels.processes.js";

import {mapState} from "vuex";

import moment from "moment";
import ProcessService from "../../../../services/ProcessService";

export default {
  name: "OperationalInconsistencies",
  props: [],
  components: {
    DatePicker,
  },
  data() {
    return {
      filter: {
        steps: [],
        exporters: [],
        customers: [],
        companies: [],
        teams: [],
        contacts: [],
        customsBrokers: [],
        date: {
          start: moment().startOf("week").toDate(),
          end: moment().endOf("week").toDate(),
        },
      },
      labels: {
        id: {
          label: "ID",
        },
        ...labelsProcesses.labels[0],
      },
      listOfCompanies: [],
      listOfCustomers: [],
      listOfTeams: [],
      listOfContacts: [],
      listOfResults: [],
      count: 0,
      totalValuesOfInconsistencies: 0.0,
    };
  },

  methods: {
    keepOpen(event) {
      event.preventDefault();
      if (event.target && (event.target.tagName === 'A' || event.target.tagName === 'UL')) {
        const dropdowns = document.querySelectorAll('.dropdown-menu');
        dropdowns.forEach((dropdown) => {
          dropdown.classList.remove('show');
        });
      } 
    },
    generate(type) {
      const {filter} = this;

      if (
        filter.date.field === "" ||
        filter.date.start === "" ||
        filter.date.end === ""
      ) {
        Swal.fire(
          "Relatório de Inconsistências",
          "Você precisa selecionar um período para conseguir gerar o relatório.",
          "error"
        );
      } else {
        this.$store.commit("setLoading", {
          show: true,
          label: "Relatório de Inconsistências",
          description: "Aguarde enquanto o relatório está sendo gerado...",
        });

        let params = "";
        const startDate = `${this.$moment(filter.date.start).format(
          "Y-MM-DDT"
        )}00:00:01`;
        const endDate = `${this.$moment(filter.date.end).format(
          "Y-MM-DDT"
        )}23:59:59`;

        if (filter.steps.length > 0) {
          const steps = new Array();
          filter.steps.filter((s) => {
            steps.push(s.id);
          });
          params += `&stepsIds=${steps.join()}`;
        }

        if (filter.customers.length > 0) {
          const customers = new Array();
          filter.customers.filter((o) => {
            customers.push(o.id);
          });
          params += `&customersIds=${customers.join()}`;
        }

        if (filter.teams.length > 0) {
          const teams = new Array();
          filter.teams.filter((o) => {
            teams.push(o.id);
          });
          params += `&teamsIds=${teams.join()}`;
        }

        if (filter.companies.length > 0) {
          const companies = new Array();
          filter.companies.filter((o) => {
            companies.push(o.id);
          });
          params += `&companiesIds=${companies.join()}`;
        }

        if (filter.contacts.length > 0) {
          const contacts = new Array();
          filter.contacts.filter((o) => {
            contacts.push(o.id);
          });
          params += `&employeeIds=${contacts.join()}`;
        }

        Axios.get(
          `${config.env.API_DEFAULT.host}/inconsistency/report/general?from=${startDate}&to=${endDate}${params}`
        )
          .then((response) => {
            if (response.data.code === "SUCCESS") {
              this.listOfResults = response.data.data.list;
              this.count = response.data.data.count;
              this.totalValuesOfInconsistencies =
                response.data.data.totalValuesOfInconsistencies;

              if (type === "PDF") {
                this.printDemonstrative(this.listOfResults);
              } else {
                this.$store.commit("setLoading", {
                  show: false,
                });
                Swal.fire(
                  "Relatório Geral de Importações",
                  response.data.message,
                  "success"
                );
              }
            } else {
              this.$store.commit("setLoading", {
                show: false,
              });
              Swal.fire(
                "Relatório Geral de Importações",
                response.data.message,
                "error"
              );
            }
          })
          .catch((error) => {
            this.$store.commit("setLoading", {
              show: false,
            });
          });
      }
    },
    printDemonstrative(data) {
      const requestConfiguration = {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.env.API_JSREPORTONLINE.credentials.token,
          responseType: "arraybuffer",
        },
      };

      const {account} = this.$store.state.credentials.data;

      delete account.logo;
      const requestData = {
        template: {
          shortid: "zNNe8GP",
          recipe: "chrome-pdf",
        },
        data: {
          account: {
            ...account,
            logo: "https://gscmx2-primeinternacional.s3.amazonaws.com/images/logo.png",
          },
          data,
        },
      };

      jsreport.serverUrl = config.env.API_JSREPORTONLINE.host;
      jsreport.headers.Authorization =
        config.env.API_JSREPORTONLINE.credentials.token;

      jsreport
        .renderAsync(requestData)
        .then((res) => {
          // open download dialog
          res.download("Relatório Geral de Importações.pdf");
          res.xhr.getResponseHeader("Content-Type");
          this.$store.commit("setLoading", {
            show: false,
          });
        })
        .catch((error) => {
          this.$store.commit("setLoading", {
            show: false,
          });
          Swal.fire(
            "Relatório Geral de Importações",
            `Houve algum erro ao tentar gerar o demonstrativo desta DI: <strong>${error.response.data.message}</strong>`,
            "error"
          );
        });
    },

    async findProcessByIdentificationSearch(identification) {
      const response = await this.$store.dispatch(
        "ProcessStore/findProcessByIdentification",
        identification
      );
      if (response !== undefined && response !== null && response.success) {
        // this.$store.commit("ProcessStore/SET_ACTIVE", response.data);
        this.$router.push("/operational/imp/desk?showProcess=true");
      } else Swal.fire("Processo de Importação", response.message, "error");
    },

    async getCompanies(search) {
      const response = await this.$store.dispatch(
        "ProcessStore/getCompanies",
        search
      );

      if (!response) {
        swal(
          "Empresas",
          "Ops, houve algum erro no carregamento das empresas! ",
          "error"
        );
      }
    },
    async getTeams(search) {
      const response = await this.$store.dispatch(
        "ProcessStore/getTeams",
        search
      );

      if (!response) {
        swal(
          "Times Operacionais",
          "Ops, houve algum erro no carregamento dos times! ",
          "error"
        );
      }
    },
    async getCustomers(search) {
      const response = await this.$store.dispatch(
        "ProcessStore/getCustomers",
        search
      );

      if (!response) {
        swal(
          "Empresas",
          "Ops, houve algum erro no carregamento dos clientes! ",
          "error"
        );
      }
    },
    async getUsers(search) {
      const response = await this.$store.dispatch(
        "ProcessStore/getUsers",
        search
      );

      if (!response) {
        swal(
          "Colaboradores",
          "Ops, houve algum erro no carregamento dos colaboradores! ",
          "error"
        );
      }
    },
  },
  watch: {},
  computed: {
    ProcessService() {
      return ProcessService
    },
    ...mapState({
      steps: (state) => state.ProcessStore.steps,
      optionsCustomers: (state) => state.ProcessStore.optionsCustomers,
      optionsCompanies: (state) => state.ProcessStore.optionsCompanies,
      optionsTeams: (state) => state.ProcessStore.optionsTeams,
      optionsUsers: (state) => state.ProcessStore.optionsUsers,
    }),
  },
  mounted() {
    this.$store.dispatch("ProcessStore/getSteps");
  },
};
</script>

<style scoped>
.vs__selected-options span.selected-tag {
  margin: 5px 0px 0px -3px !important;
  padding: 5px !important;
}
</style>
