<template>
  <div class="example-full">
    <div class="report-summary-header mg-b-5 mg-t-0">
      <div>
        <h4 class="tx-inverse mg-b-3">Enviar Documentos</h4>
        <p class="mg-b-0">
          <i class="icon ion-information-circled mg-r-3"></i> Selecione os
          documentos que você deseja enviar pra o processo e arraste logo
          abaixo.
        </p>
      </div>

      <div>
        <div class="btn-group">
          <file-upload
            ref="upload"
            v-model="files"
            :accept="accept"
            :add-index="addIndex"
            :custom-action="customAction"
            :data="data"
            :directory="directory"
            :drop="drop"
            :drop-directory="dropDirectory"
            :extensions="extensions"
            :headers="headers"
            :maximum="30"
            :multiple="multiple"
            :size="size || 0"
            :thread="thread"
            class="btn btn-primary dropdown-toggle btn-oblong"
            @input-filter="inputFilter"
            @input-file="inputFile"
          >
            <i class="fa fa-plus mg-r-10"></i>
            SELECIONAR
          </file-upload>
          <div class="dropdown-menu">
            <label :for="name" class="dropdown-item">DOCUMENTOS</label>
            <a class="dropdown-item" href="#" @click="onAddFolader"
              >PASTA COMPLETA DE DOCUMENTOS</a
            >
          </div>
        </div>

        <a
          class="mg-l-20 btn btn-danger btn-oblong btn-sm"
          href="#"
          @click.prevent="files = []"
        >
          <i class="icon ion-trash-a tx-22"></i>
          LIMPAR
        </a>
        <a
          class="btn btn-success btn-oblong btn-sm"
          href="#"
          @click.prevent="$refs.upload.active = true"
        >
          <i class="icon ion-upload tx-22"></i>
          ENVIAR
        </a>
      </div>
    </div>

    <hr />

    <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
      <h3>Arraste os documentos para fazer upload!</h3>
    </div>

    {{ xmlDICheckErrors }}

    <div class="upload ht-100p">
      <div class="row no-gutters">
        <table class="table table-striped mg-b-0">
          <thead>
            <tr>
              <th class="tx-center">#</th>
              <th class="tx-center">Nome do Arquivo</th>
              <th class="tx-center">Descrição</th>
              <th class="tx-center">Tipo de Documento</th>
              <th class="tx-center">Tamanho</th>
              <th class="tx-center">Ação</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!files.length">
              <td colspan="8">
                <div class="card card-status tx-center">
                  <div class="media pd-40">
                    <i class="icon ion-ios-cloud-upload-outline tx-primary"></i>
                    <div class="media-body">
                      <h1>Upload de Documentos</h1>
                      <p class="tx-18">
                        Arraste os documentos aqui para fazer upload deles.
                      </p>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <template v-for="(file, index) in files">
              <tr :key="'row-' + index">
                <td class="tx-center">{{ index + 1 }}</td>

                <td>
                  <div class="filename tx-12">
                    {{ file.name }}
                  </div>
                  <div
                    v-if="file.active || file.progress !== '0.00'"
                    class="progress"
                  >
                    <div
                      :class="{
                        'progress-bar': true,
                        'progress-bar-striped': true,
                        'bg-danger': file.error,
                        'bg-danger': file.errorMsg,
                        'bg-success': file.success,
                        'progress-bar-animated': file.active,
                      }"
                      :style="{ width: file.progress + '%' }"
                      role="progressbar"
                    >
                      {{ file.progress }}%
                    </div>
                  </div>
                </td>

                <td>
                  <input
                    v-model="file.description"
                    class="form-control pd-0"
                    type="text"
                  />
                  <!-- <div class="input-group-solved">
                    <div class="input-group-solved-before mg-r-5"><i class="fa fa-info op-5"></i></div>
                    <div class="input-group-solved-rigth wd-100p">
                        <input type="text" v-model="file.description" class="form-control ">
                    </div>
                </div> -->

                  <span v-if="file.errorMsg" class="tx-12 d-block">
                    <span
                      class="square-8 bg-danger mg-r-5 rounded-circle"
                    ></span>
                    <span class="tx-uppercase tx-danger">
                      {{ file.errorMsg }}
                    </span>
                  </span>
                  <span v-else-if="file.successMsg" class="tx-12 d-block">
                    <span
                      class="square-8 bg-success mg-r-5 rounded-circle"
                    ></span>
                    <span class="tx-uppercase tx-success">
                      {{ file.successMsg }}
                    </span>
                  </span>
                </td>

                <td>
                  <v-select
                    v-model="file.documentType"
                    :options="documentsTypes"
                    :on-input="checkIfDocumentIsDI(file)"
                    class="wd-100p"
                    label="description"
                    name="documentType"
                  >
                    <template slot="option" slot-scope="option">
                      {{ option.description }}
                    </template>
                    <template slot="no-options"> Não encontrado</template>
                  </v-select>
                </td>

                <td class="tx-center wd-100">{{ file.size | formatSize }}</td>

                <td class="tx-center wd-120">
                  <a
                    v-if="file.errorMsg"
                    class="btn btn-success btn-icon rounded-circle mg-r-5"
                    href="#"
                    @click.prevent="
                      customAction(file);
                      file.active = true;
                    "
                  >
                    <div><i class="icon ion-refresh"></i></div>
                  </a>

                  <a
                    class="btn btn-danger btn-icon rounded-circle"
                    href="#"
                    @click.prevent="$refs.upload.remove(file)"
                  >
                    <div><i class="icon ion-trash-a"></i></div>
                  </a>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import ImageCompressor from "@xkeshi/image-compressor";
import FileUpload from "vue-upload-component";
import vSelect from "vue-select";
import { mapState } from "vuex";
import config from "@/config.js";
import Axios from "axios";
import Swal from "sweetalert2";
import {EventBus} from "@/events/EventBus";

export default {
  name: "FormDocsUpload",
  components: {
    FileUpload,
    "v-select": vSelect,
  },
  data() {
    return {
      files: [],
      accept:
        "application/pdf,text/plain,application/xml,application/msword,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      extensions: "pdf,txt,doc,docx,xml,xls,xlsx,zip,rar",
      minSize: 1024,
      size: 1024 * 1024 * 50,
      multiple: true,
      directory: false,
      drop: true,
      dropDirectory: true,
      addIndex: false,
      thread: 30,
      name: "file",
      data: {},
      autoCompress: 1024 * 1024,
      uploadAuto: false,
      uploadPercentage: 0,
      xmlDICheckReturnValue: true,
      xmlDICheckErrors: "",
    };
  },
  computed: {
    headers() {
      return {
        Authorization: `Bearer ${this.$store.state.credentials.accessToken}`,
        // 'Content-Type': 'multipart/form-data'
      };
    },
    configAPIHost() {
      return config.env.API_DEFAULT.host;
    },
    uploadURL() {
      if (config) {
        return `${config.env.API_DEFAULT.host}/documents/process/import/${this.process.id}`;
      }
      return "";
    },
    ...mapState({
      process: (state) => state.ProcessStore.activeProcess,
      documentsTypes: (state) => state.ProcessStore.documentsTypes,
    }),
  },

  methods: {
    checkDIXml(file) {
      return new Promise((resolve) => {
        // Convert XML to String
        const reader = new FileReader();

        reader.onload = (event) => {
          const insuranceDataErrors = [];
          const xmlString = event.target.result;

          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(xmlString, "text/xml");

          const diObject = xmlDoc
            .getElementsByTagName("ListaDeclaracoes")[0]
            .getElementsByTagName("declaracaoImportacao")[0];
          const fob = parseInt(
            diObject.getElementsByTagName("localEmbarqueTotalReais")[0]
              .textContent
          ).toString();
          const freight = parseInt(
            diObject.getElementsByTagName("freteTotalReais")[0].textContent
          ).toString();
          const insurance = parseInt(
            diObject.getElementsByTagName("seguroTotalReais")[0].textContent
          ).toString();

          const processFob = this.process.fobValue.toString().replace(".", "");
          const processFreight = this.process.freightTotalValue
            .toString()
            .replace(".", "");
          const processInsuranceValue = this.process.insuranceAward
            .toString()
            .replace(".", "");

          if (processFob === null || processFob === undefined) {
            insuranceDataErrors.push(
              "O Valor <strong>FOB</strong> não foi preenchido no processo!"
            );
          } else if (fob !== processFob) {
            insuranceDataErrors.push(
              "O Valor <strong>FOB</strong> da DI é diferente do cadastrado no processo!"
            );
          }

          //   if (processFreight === null || processFreight === undefined) {
          //     insuranceDataErrors.push('O Valor de <strong>FRETE</strong> não foi preenchido no processo!');
          //   } else {
          //     if (freight !== processFreight) {
          //       insuranceDataErrors.push('O Valor de <strong>FRETE</strong> da DI é diferente do cadastrado no processo!');
          //     }
          //   }

          if (process.stepInsuranceSolved) {
            if (
              processInsuranceValue === null ||
              processFreight === undefined
            ) {
              insuranceDataErrors.push(
                "O Valor do <strong>PRÊMIO</strong> não foi preenchido no processo!"
              );
            } else if (insurance !== processInsuranceValue) {
              insuranceDataErrors.push(
                "O Valor de <strong>PRÊMIO</strong> da DI é diferemte do cadastro do processo!"
              );
            }
          }

          if (insuranceDataErrors.length > 0) {
            let errorsElements = "";
            insuranceDataErrors.forEach(
              (message) => (errorsElements += `<li>${message}</li>`)
            );

            resolve({
              value: false,
              message: errorsElements,
            });
          } else {
            resolve({
              value: true,
              message: null,
            });
          }
        };

        reader.readAsText(file, "UTF-8");
      });
    },
    async customAction(file, component) {
      try {
        // TODO: Verificação do XML da DI desabilitado por hora
        // if (file.type === 'text/xml' && (file.documentType.code === 'TD130' || file.documentType.description === 'XML DI')) {
        //   const {value, message} = await this.checkDIXml(file.file);

        //   if (!value) {
        //     file.errorMsg = "Confira os valores da DI com os preenchidos na aba de seguro!";

        //     await Swal.fire({
        //       title: "Divergência de informações",
        //       html: `
        //                       <p>Alguns valores da DI estão diferentes dos preenchidos na aba de seguros, confira abaixo:</p>
        //                       <ul>
        //                           ${message}
        //                       </ul>
        //                   `,
        //       type: 'warning'
        //     });

        //     return false;
        //   }
        // }

        if (
          (file.documentType === undefined || file.documentType === null) &&
          (file.description === undefined ||
            file.description == null ||
            file.description.length == 0)
        ) {
          file.errorMsg =
            "Preencha a Descrição e selecione o Tipo de Documento.";
          return false;
        }
        if (file.documentType === undefined || file.documentType === null) {
          file.errorMsg = " Tipo de Documento não selecionado.";
          return false;
        }
        if (
          file.description === undefined ||
          file.description == null ||
          file.description.length == 0
        ) {
          file.errorMsg = " Descrição não preenchida.";
          return false;
        }
        file.errorMsg = "";

        const formData = new FormData();
        formData.append("files[0]", file.file);

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) =>
            (file.progress = parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            )),
        };

        const response = await Axios.post(
          `${this.configAPIHost}/documents/process/import/${this.process.id}/${
            file.documentType.id
          }/${encodeURI(file.description)}`,
          formData,
          config
        );
        if (response) {
          if (response.status === 200) {
            if (response.data.code === "ERROR") {
              file.errorMsg = response.data.message;
            } else if (
              response.data.message !== null &&
              response.data.message !== undefined
            ) {
              file.successMsg = response.data.message;
              const response2 = await Axios.get(
                `${this.configAPIHost}/process/import/checkEvent/${this.process.id}/${file.documentType.id}/check/true`
              );

              if (response2) {
                let msg = "";
                let message = "";
                let messageItemText = "";

                if (
                  response2.data.listOfMessages !== undefined &&
                  response2.data.listOfMessages !== null &&
                  response2.data.listOfMessages.length > 0
                ) {
                  message +=
                    "<ul class='list-group list-group-striped tx-14 tx-left'>";
                  response2.data.listOfMessages.forEach((item) => {
                    message += `<li class='list-group-item tx-left'><p class='mg-b-0 tx-left'><i class='fa fa-check tx-success mg-r-8'></i><span class='tx-inverse tx-normal'>${item}</span></p></li>`;
                    messageItemText += `${item}\n`;
                  });
                  msg = `<p class='mg-b-5 tx-18'>Os eventos abaixo foram encontrados, você deseja dispará-los?</p>${message}`;
                  msg = `<div class='tx-left'>${msg}</div>`;
                  message += "</ul>";
                } else {
                  msg = response2.data.message;
                }

                if (response2.data.code === "SUCCESS") {
                  Swal.fire(
                    `Eventos do Processo ${this.process.identification}`,
                    msg,
                    "success"
                  );

                  Swal.fire({
                    title: `Eventos do Processo ${this.process.identification}`,
                    html: msg,
                    showCancelButton: true,
                    type: "warning",
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    cancelButtonText: "Não enviar",
                    confirmButtonText: "Enviar",
                  }).then((result) => {
                    if (result.value) {
                      this.$store.commit("setLoading", {
                        show: true,
                        label: "Enviando os eventos abaixo",
                        description: messageItemText,
                      });

                      // if (this.validateDiInfo(this.process)) {
                      Axios.get(
                        `${this.configAPIHost}/process/import/checkEvent/${this.process.id}/${file.documentType.id}/check/false`
                      )
                        .then((response) => {
                          this.$store.commit("setLoading", { show: false });

                          let msg2 = "";
                          let message2 = "";

                          if (response.data.data.length > 1) {
                            message2 +=
                              "<ul class='list-group list-group-striped tx-14 tx-left'>";
                            response.data.data.forEach((item) => {
                              if (item.code === "SUCCESS") {
                                message2 += `<li class='list-group-item tx-left'><p class='mg-b-0 tx-left'><i class='fa fa-check tx-success mg-r-8'></i><span class='tx-inverse tx-normal'>${item.message}</span></p></li>`;
                              } else if (item.code === "ERROR") {
                                message2 += `<li class='list-group-item tx-left'><p class='mg-b-0 tx-left'><i class='fa fa-check tx-error mg-r-8'></i><span class='tx-inverse tx-normal'>${item.message}</span></p></li>`;
                              }
                            });
                            msg2 = `<p class='mg-b-5 tx-18'>Resultado dos eventos disparados: </p>${message}`;
                            msg2 = `<div class='tx-left'>${msg2}</div>`;
                            message2 += "</ul>";

                            Swal.fire({
                              position: "top-end",
                              icon: "success",
                              title: `Eventos do Processo ${this.process.identification}`,
                              html: message2,
                              showConfirmButton: false,
                              timer: 2000,
                            });
                          } else if (
                            response.data.data[0] !== undefined &&
                            response.data.data[0].code === "SUCCESS"
                          ) {
                            if (
                              response.data.data[0].actions !== undefined &&
                              response.data.data[0].actions !== null &&
                              response.data.data[0].actions.CHANGE_STEP !==
                                undefined &&
                              response.data.data[0].actions.CHANGE_STEP !== null
                            ) {
                              const step =
                                response.data.data[0].actions.CHANGE_STEP;
                              const { process } = this;
                              process.step = step;

                              const newData = {
                                diff: {
                                  step: `${this.configAPIHost}/processSteps/${step.id}`,
                                },
                                newModel: {
                                  ...this.process,
                                  step: {
                                    ...step,
                                  },
                                },
                              };

                              // this.$store.dispatch("patchImportProcess", newData);
                              this.$store.dispatch(
                                "ProcessStore/patchImportProcess",
                                newData
                              );

                              EventBus.$emit(
                                "Operational.imp.Form.Sidebar.Timeline.load"
                              );

                              Swal.fire({
                                type: "success",
                                title: `Eventos do Processo ${this.process.identification}`,
                                html: `O Follow UP foi enviado com sucesso e a etapa do processo foi alterada para <strong>${step.description}</strong>.`,
                                showConfirmButton: true,
                                timer: 5000,
                              });
                            } else {
                              EventBus.$emit(
                                "Operational.imp.Form.Sidebar.Timeline.load"
                              );

                              Swal.fire({
                                type: "success",
                                title: `Eventos do Processo ${this.process.identification}`,
                                html: response.data.data[0].message,
                                showConfirmButton: true,
                                timer: 5000,
                              });
                            }
                          } else {
                            Swal.fire(
                              "Eventos do Processo",
                              `Houve algum problema ao tentar processar o evento: ${response.data.data[0].message}`,
                              "error"
                            );
                          }
                        })
                        .catch((error) => {
                          Swal.fire(
                            "Eventos do Processo",
                            `Houve algum problema ao tentar processar o evento: ${error.response.data.message}`,
                            "error"
                          );
                        })
                        .finally(() => {
                          this.$store.commit("setLoading", {
                            show: false,
                          });
                        });
                      // }

                      this.$store.commit("setLoading", {
                        show: false,
                      });
                    }
                  });
                } else if (response2.data.code === "ERROR") {
                  Swal.fire(
                    `Eventos do Processo ${data.newModel.identification}`,
                    msg,
                    "error"
                  );
                } else {
                  // Swal.fire('Eventos', msg, 'success');
                }
              }
            } else {
              file.successMsg = "Documento enviado com sucesso.";
            }
          }

          file.active = false;
        }
      } catch (err) {
        if (err.response.status === 500) {
          file.errorMsg =
            "Erro inesperado no envio de documentos, entre em contato com o nosso suporte!";
        }

        file.errorMsg = `Houve um problema ao tentar fazer o upload: ${err}`;
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // Filter system files or hide files
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }
        // Filter php html js file
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent();
        }
        // Automatic compression
        if (
          newFile.file &&
          newFile.type.substr(0, 6) === "image/" &&
          this.autoCompress > 0 &&
          this.autoCompress < newFile.size
        ) {
          newFile.error = "compressing";
          const imageCompressor = new ImageCompressor(null, {
            convertSize: Infinity,
            maxWidth: 512,
            maxHeight: 512,
          });
          imageCompressor
            .compress(newFile.file)
            .then((file) => {
              this.$refs.upload.update(newFile, {
                error: "",
                file,
                size: file.size,
                type: file.type,
              });
            })
            .catch((err) => {
              this.$refs.upload.update(newFile, {
                error: err.message || "compress",
              });
            });
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        // Create a blob field
        newFile.blob = "";
        const URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file);
        }
        // Thumbnails
        newFile.thumb = "";
        if (newFile.blob && newFile.type.substr(0, 6) === "image/") {
          newFile.thumb = newFile.blob;
        }
      }

      try {
        newFile.description = newFile.name.split(".").slice(0, -1).join(".");
      } catch (error) {
        if (newFile != undefined) newFile.description = newFile.name;
      }
    },
    // add, update, remove File Event
    inputFile(newFile, oldFile) {
      if (newFile && oldFile) {
        // update
        if (newFile.active && !oldFile.active) {
          // beforeSend
          // min size
          if (
            newFile.size >= 0 &&
            this.minSize > 0 &&
            newFile.size < this.minSize
          ) {
            this.$refs.upload.update(newFile, {
              error: "size",
            });
          }
        }
        if (newFile.progress !== oldFile.progress) {
          // progress
        }
        if (newFile.error && !oldFile.error) {
          // error
        }
        if (newFile.success && !oldFile.success) {
          // success
        }
      }
      if (!newFile && oldFile) {
        // remove
        if (oldFile.success && oldFile.response.id) {
          // $.ajax({
          //   type: 'DELETE',
          //   url: '/upload/delete?id=' + oldFile.response.id,
          // })
        }
      }
      // Automatically activate upload
      if (
        Boolean(newFile) !== Boolean(oldFile) ||
        oldFile.error !== newFile.error
      ) {
        if (this.uploadAuto && !this.$refs.upload.active) {
          this.$refs.upload.active = true;
        }
      }
    },
    alert(message) {
      alert(message);
    },

    onAddFolader() {
      if (!this.$refs.upload.features.directory) {
        this.alert(
          "O navegador que você está utilizando não suporte este tipo de função."
        );
        return;
      }
      const input = this.$refs.upload.$el.querySelector("input");
      input.directory = true;
      input.webkitdirectory = true;
      this.directory = true;
      input.onclick = null;
      input.click();
      input.onclick = (e) => {
        this.directory = false;
        input.directory = false;
        input.webkitdirectory = false;
      };
    },
    validateDiInfo(process) {
      let errorHtml = `<p class='mg-t-20 mg-b-10 text-left'>Antes de fazer upload da DI, preencha os campos abaixo e tente novamente.</p>
        <ul class="list-group list-group-striped tx-14">`;

      const inErrors = [];
      if (
        process.diNumber == "" ||
        process.diNumber == null ||
        process.diNumber == undefined
      ) {
        inErrors.push("Número de Registro da DI");
      }
      if (
        process.diRegistryDate == "" ||
        process.diRegistryDate == null ||
        process.diRegistryDate == undefined
      ) {
        inErrors.push("Data de Registro da DI");
      }
      // if (process.diTypingDate == '' || process.diTypingDate == null || process.diTypingDate == undefined) {
      //     inErrors.push("Data de Digitação da DI");
      // }
      // if (process.diResourcefulnessDate == '' || process.diResourcefulnessDate == null || process.diResourcefulnessDate == undefined) {
      //     inErrors.push("Data de Desembaraço da DI");
      // }
      // if (process.diChannel == '' || process.diChannel == null || process.diChannel == undefined) {
      //     inErrors.push("Canal da DI");
      // }

      if (inErrors.length > 0) {
        for (let i = 0; i < inErrors.length; i++) {
          errorHtml += `
              <li class="list-group-item">
                <p class="mg-b-0">
                  <i class="fa fa-check tx-danger mg-r-8" aria-hidden="true"></i>
                  <span class="tx-inverse tx-normal">
                    ${inErrors[i]}
                  </span>
                </p>
              </li>
            `;
        }

        errorHtml += "</ul>";

        Swal.fire({
          title: `Eventos do Processo ${process.identification}`,
          html: errorHtml,
          type: "error",
        });

        return false;
      }

      return true;
    },
    checkIfDocumentIsDI(file) {
      if (file.documentType !== null && file.documentType !== undefined) {
        if (
          file.documentType.code === "TD4" &&
          file.documentType.description === "DI"
        ) {
          const validated = this.validateDiInfo(this.process);

          if (!validated) {
            this.files = this.files.filter((outFile) => outFile.id != file.id);
          }
        }
      }
    },
  },
};
</script>

<style>
.example-full .btn-group .dropdown-menu {
  display: block;
  visibility: hidden;
  transition: all 0.2s;
}

.example-full .btn-group:hover > .dropdown-menu {
  visibility: visible;
}

.example-full label.dropdown-item {
  margin-bottom: 0;
}

.example-full .btn-group .dropdown-toggle {
  margin-right: 0.6rem;
}

.example-full .filename {
  margin-bottom: 0.3rem;
}

.example-full .btn-is-option {
  margin-top: 0.25rem;
}

.example-full .example-foorer {
  padding: 0.5rem 0;
  border-top: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
}

.example-full .edit-image img {
  max-width: 100%;
}

.example-full .edit-image-tool {
  margin-top: 0.6rem;
}

.example-full .edit-image-tool .btn-group {
  margin-right: 0.6rem;
}

.example-full .footer-status {
  padding-top: 0.4rem;
}

.example-full .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}

.example-full .drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}
</style>
