<template>
  <form class="form-layout form-layout-2">
    <imp-form-inconsistencies-modal
      v-if="modal.show"
      @close="closeModal('close')"
      @save="save()"
      @finish="closeModal('finish')"
      :model="inconsistency"
      :isForm="false"
    ></imp-form-inconsistencies-modal>

    <div class="report-summary-header mg-b-5 mg-t-0">
      <div>
        <h4 class="tx-inverse mg-b-3">Inconsistências do Processo</h4>
        <p class="mg-b-0">
          <i class="icon ion-information-circled mg-r-3"></i> Lançamento de
          inconsistências do processo.
        </p>
      </div>

      <div>
        <!-- <a href="#" class="btn btn-secondary" @click="print"><i class="icon ion-document-text tx-22"></i> Extrato </a> -->
        <a href="#" class="btn btn-info" @click="showModal"
        ><i class="icon ion-plus tx-22"></i> ADICIONAR
        </a>
      </div>
    </div>

    <div class="table-responsive">
      <table
        class="table display responsive nowrap dataTable no-footer dtr-inline tx-13"
      >
        <thead>
        <tr class="tx-10">
          <th class="pd-y-5 tx-center">Data</th>
          <th class="pd-y-5 tx-center">Origem</th>
          <th class="pd-y-5 tx-center">Descrição / Tipo</th>
          <th class="pd-y-5 tx-center">Valor R$</th>
          <th class="pd-y-5 tx-center">Peso</th>
          <th class="pd-y-5 tx-center">Etapa</th>
          <th class="pd-y-5 tx-center">Ações</th>
        </tr>
        </thead>
        <template v-if="this.inconsistencies.length > 0">
          <tbody>
          <tr
            v-for="item in inconsistencies"
            :key="'inconsistency-id-' + item.id"
          >
            <td class="valign-middle tx-center">
              {{ item.date | moment("DD/MM/YYYY") }}
            </td>
            <td class="valign-middle tx-center">
                <span
                  class="tx-danger tx-bold tx-lato"
                  v-if="item.source === 'INTERNA'"
                >
                  Interna
                </span>
              <span
                class="tx-warning tx-bold tx-lato"
                v-else-if="item.source === 'FORNECEDOR'"
              >
                  Fornecedor
                </span>
              <span
                class="tx-primary tx-bold tx-lato"
                v-else-if="item.source === 'CLIENTE'"
              >
                  Cliente
                </span>
              <br/>
              <small
                v-if="
                    item.employee !== undefined &&
                    item.employee !== null &&
                    item.source === 'INTERNA'
                  "
              >
                {{ item.employee.name }}</small
              >
              <small
                v-else-if="
                    item.company !== undefined &&
                    item.company !== null &&
                    item.source === 'FORNECEDOR'
                  "
              >
                {{ item.company.name }}</small
              >
              <small
                v-else-if="
                    item.company !== undefined &&
                    item.company !== null &&
                    item.source === 'CLIENTE'
                  "
              >
                {{ item.company.name }}</small
              >
            </td>
            <td class="valign-middle tx-center">
                <span v-if="item.description != null">
                  {{ item.description }} <br/>
                </span>
              <strong>{{ item.type }}</strong>
            </td>
            <td class="valign-middle tx-center tx-right tx-12 tx-bold">
              {{ item.value | numeralFormat("0,0.00") }}
            </td>
            <td class="valign-middle tx-center tx-center tx-12 tx-bold">
              {{ item.weight }}
            </td>
            <td class="valign-middle tx-center">
                <span v-if="item.step != null">
                  {{ item.step.description }}
                </span>
              <span v-else> Nenhuma Etapa </span>
            </td>
            <td class="valign-middle tx-center">
              <a
                href="#"
                @click.prevent="remove(item)"
                class="btn btn-danger btn-icon rounded-circle"
              >
                <div><i class="icon ion-trash-a"></i></div>
              </a>

              <a
                href="#"
                @click.prevent="edit(item)"
                class="btn btn-warning btn-icon rounded-circle mg-l-10"
              >
                <div><i class="icon ion-edit"></i></div>
              </a>
            </td>
          </tr>
          </tbody>
        </template>
        <template v-else>
          <tbody>
          <tr>
            <td colspan="7" class="valign-middle tx-center">
              Nenhuma inconsistência foi cadastrada.
            </td>
          </tr>
          </tbody>
        </template>
      </table>
    </div>
  </form>
</template>

<script>
import Axios from "axios";
import {mapState} from "vuex";
import ModalInconsistencies from "@/views/operational/imp/inconsistencies/Modal.vue";
import Swal from "sweetalert2";

export default {
  name: "imp-form-inconsistencies",
  components: {
    "imp-form-inconsistencies-modal": ModalInconsistencies,
  },
  props: {
    inconsistencies: Array,
  },
  data() {
    return {
      modal: {
        show: false,
      },
      inconsistency: {
        responsability: "",
        company: null,
        employee: null,
        description: null,
        weight: null,
        type: null,
        value: 0.0,
        step: null,
        importProcess: null,
        exportProcess: null,
        date: null,
      },
      // inconsistencies: []
    };
  },
  methods: {
    closeModal(type) {
      this.modal.show = false;
      this.inconsistency = {
        responsability: "",
        company: null,
        employee: null,
        description: null,
        weight: null,
        type: null,
        value: 0.0,
        step: null,
        importProcess: null,
        exportProcess: null,
        date: null,
      };
    },
    showModal() {
      this.modal.show = true;
    },
    print() {
      this.$htmlToPaper("print");
    },
    changeShowAdd(showAdd) {
      this.showAdd = showAdd;
    },
    async getCompanies(search) {
      const response = await this.$store.dispatch(
        "ProcessStore/getCompanies",
        search
      );

      if (!response) {
        swal(
          "Empresas",
          "Ops, houve algum erro no carregamento das empresas! ",
          "error"
        );
      }
    },

    edit(value) {
      this.inconsistency = value;
      this.showModal();
    },
    async remove(value) {
      Swal.fire({
        title: "Excluir Inconsistência",
        html: "Você realmente deseja excluir esta inconsistência?",
        type: "warning",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sim, excluir!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if ("value" in result && result.value) {
          try {
            this.$store.commit("setLoading", {
              show: true,
              label: "Inconsistências",
              description: "Processando requisição, aguarde...",
            });

            await Axios.delete(value._links.self.href, value);
            const index = this.inconsistencies.findIndex(
              (item) => item._links.self.href === value._links.self.href
            );

            this.inconsistencies.splice(index, 1);
            this.$store.commit("setLoading", {
              show: false,
              label: "",
              description: "",
            });
          } catch (err) {
            this.$store.commit("setLoading", {
              show: false,
              label: "",
              description: "",
            });

            this.$swal("Ops", err, "error");
          }
        }
      });
    },

    // async remove(value) {
    //     try {
    //         this.$store.commit('setLoading', {
    //             show: true,
    //             label: `Inconsistências`,
    //             description: 'Processando requisição, aguarde...'
    //         });
    //         const response = await Axios.delete(value._links.self.href, value)
    //         const index = this.inconsistencies.findIndex(item => item._links.self.href === value._links.self.href)
    //         this.inconsistencies.splice(index, 1)
    //         this.$store.commit('setLoading', {
    //             show: false,
    //             label: ``,
    //             description: ''
    //         });

    //     } catch (err) {
    //         this.$store.commit('setLoading', {
    //             show: false,
    //             label: ``,
    //             description: ''
    //         });
    //         this.$swal('Ops', err, "error");
    //     }
    // },
  },
  computed: {
    ...mapState({
      process: (state) => state.ProcessStore.activeProcess,
      optionsCompanies: (state) => state.ProcessStore.optionsCompanies,
      steps: (state) => state.ProcessStore.steps,
    }),
  },
  created() {
    this.model = {
      ...this.process,
    };
  },
  watch: {
    process() {
      this.model = {
        ...this.process,
      };
    },
  },
};
</script>

<style scoped>
.v-select .dropdown-toggle {
  border: none !important;
  border-radius: 0px !important;
  padding: 0px !important;
  margin-top: -9px !important;
}
</style>
